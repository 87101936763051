import React, {
  useState,
  useRef,
  useEffect,
  useContext,
  Fragment,
} from "react";
import { Auth } from "aws-amplify";
import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";
import Loading from "util/loading";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";

import { Card, CardContent } from "@mui/material";

import { Add } from "@mui/icons-material";

import base32 from "hi-base32";
import * as uuid from "uuid";

const Item = (params) => {
  const [loading, setLoading] = useState(false);
  const [qrData, setQrData] = useState("");

  const mounted = useRef(null);

  useEffect(() => {
    mounted.current = true;

    async function generateQrData() {
      try {
        setLoading(true);
        const user = await Auth.currentAuthenticatedUser({
          bypassCache: true,
        });

        const key = user.attributes.sub.replace(/-/g, "").substr(0, 20);
        const secret = base32.encode(key).replace(/=/g, "");
        const username = "Neo: " + user.attributes.email;
        const data = encodeURI(
          "otpauth://totp/" + username + "?secret=" + secret + "&issuer=Neo"
        );
        setQrData(data);
        console.log("data ", data);
      } catch (error) {
        console.log(error);
        NotificationManager.error(error.toString(), error.message, 3000);
      } finally {
        setLoading(false);
      }
    }
    generateQrData();

    return function cleanup() {
      mounted.current = false;
    };
  }, []);

  return (
    <Fragment>
      <Loading loading={Boolean(loading)} />
      <NotificationContainer />

      <Card className="shadow border-0 mt-3">
        <CardContent>
          <Box lineHeight={3}>
            <p>
              Para acessar alguns recursos do sistema, você precisa habilitar a
              autenticação de 2 fatores.
            </p>
            <p>Se ainda não o fez, siga os passos abaixo:</p>
            <ol>
              <li>Acesse a Play Store (Android) ou a App Store (iOS).</li>
              <li>Instale o Google Authenticator.</li>
              <li>
                Clique no botão{" "}
                <Fab className="jr-fab-btn bg-primary text-white jr-btn-fab-xs ml-auto">
                  <Add />
                </Fab>
              </li>
              <li>Escaneie o QR code abaixo.</li>
            </ol>

            <Box className="w100 p-3" display="flex" justifyContent="center">
              {qrData.length > 0 && (
                <img
                  src={`https://chart.googleapis.com/chart?chs=166x166&chld=L|0&cht=qr&chl=${qrData}`}
                ></img>
              )}
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Fragment>
  );
};

export default Item;
