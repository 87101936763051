import React, { useState, useRef, useEffect, useContext } from 'react';
import IntlMessages from 'util/IntlMessages';
import { Auth } from 'aws-amplify';
import {
  NotificationManager,
  NotificationContainer,
} from 'react-notifications';
import Loading from 'util/loading';
import axios from 'axios';

import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Switch from '@mui/material/Switch';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { Card, CardContent } from '@mui/material';

import Context from './context';
import * as actions from './actions';

import totp from 'util/totp';
import base32 from 'hi-base32';

const allUrns = require('util/urns.json');

const Item = () => {
  const { state, dispatch } = useContext(Context);
  const [currentOperatorId, setCurrentOperatorId] = useState('');
  const [validationCode, setValidationCode] = useState('');
  const [validKey, setValidKey] = useState(false);
  const [currentOperatorResources, setCurrentOperatorResources] = useState([]);
  const [validationKeyDialogShow, setValidationKeyDialogShow] = useState(false);
  const [operatorsList, setOperatorsList] = useState([]);

  const mounted = useRef(null);

  useEffect(() => {
    mounted.current = true;

    listOperators();

    return function cleanup() {
      mounted.current = false;
    };
  }, []);

  // busca a lista de operadores
  async function listOperators() {
    const url =
      'https://20arn6e6dj.execute-api.us-east-1.amazonaws.com/production/listoperators2?resource_id=urn:*';

    try {
      dispatch({ type: actions.LOADING, payload: true });
      const result = await axios.get(url);

      if (mounted.current) {
        setOperatorsList(result.data.sort(compare));
      }
    } catch (error) {
      console.log(error);
      NotificationManager.error(error.message, null, 3000);
    } finally {
      if (mounted.current) {
        dispatch({ type: actions.LOADING, payload: false });
      }
    }
  }

  function compare(a, b) {
    // decrescente
    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
    if (b.name.toLowerCase() > a.name.toLowerCase()) return -1;

    return 0;
  }

  function handleHideDialog() {
    if (mounted.current) {
      setValidationCode('');
      setValidationKeyDialogShow(false);
    }
  }

  async function handleChangeKey() {
    try {
      dispatch({ type: actions.LOADING, payload: true });
      let validCode = false;
      let user = await Auth.currentAuthenticatedUser({
        bypassCache: true,
      });

      if (user.attributes.hasOwnProperty('custom:secretKey')) {
        let key = user.attributes['custom:secretKey'];
        key = key.replace(/-/g, '').substr(0, 20);
        let secret = base32.encode(key).replace(/=/g, '');
        let codes = await totp(secret);

        for (let code of codes) {
          let c1 = code.replace(/ /g, '');
          let c2 = validationCode.trim();
          if (c1 === c2) {
            validCode = true;
          }
        }
      }

      if (mounted.current) {
        setValidKey(validCode);

        if (!validCode) {
          throw new Error('Chave inválida');
        }
      }
    } catch (error) {
      NotificationManager.error(error.message, null, 3000);
    } finally {
      if (mounted.current) {
        setValidationCode('');
        setValidationKeyDialogShow(false);
        dispatch({ type: actions.LOADING, payload: false });
      }
    }
  }

  // tenta atualizar (ou adicionar) uma URN
  async function changeResource(aUrn, aEnabled) {
    try {
      let authToken = (await Auth.currentSession()).getIdToken().getJwtToken();
      let url =
        'https://20arn6e6dj.execute-api.us-east-1.amazonaws.com/production/updateresource';

      var options = {
        url: url,
        method: 'post',
        headers: {
          Authorization: 'Bearer ' + authToken,
        },
        data: {
          operator_id: currentOperatorId,
          resource_id: aUrn,
          value: aEnabled,
        },
      };

      dispatch({ type: actions.LOADING, payload: true });
      let result = await axios(options);

      // jeito feio: ele lista novamente todos os provedores, para atualizar as urns apenas do selecionado
      await listOperators();
    } catch (error) {
      console.log(error);
      NotificationManager.error(error.message, null, 3000);
    } finally {
      dispatch({ type: actions.LOADING, payload: false });
      // dispatch({ type: actions.SHOW_DIALOG, payload: false });
    }
  }

  // tenta adicionar uma nova urn ao provedor
  function handleAddUrn(aEvent, aUrn) {
    for (let resource of currentOperatorResources) {
      if (resource.urn === aUrn) {
        NotificationManager.info('URN já presente', null, 3000);

        return;
      }
    }

    changeResource(aUrn, false);
  }

  // busca as informações do provedor selecionado, para atualizar a lista de recursos (urns)
  useEffect(() => {
    async function updateResources() {
      if (currentOperatorId === '') {
        return;
      }

      const url =
        'https://20arn6e6dj.execute-api.us-east-1.amazonaws.com/production/getOperatorResources?idp=' +
        currentOperatorId;

      console.log(url);

      try {
        dispatch({ type: actions.LOADING, payload: true });
        const result = await axios.get(url);

        if (mounted.current && 'data' in result) {
          setCurrentOperatorResources(result.data);
        }
      } catch (error) {
        console.log(error);
        NotificationManager.error(error.message, null, 3000);
      } finally {
        if (mounted.current) {
          dispatch({ type: actions.LOADING, payload: false });
        }
      }
    }

    updateResources();
  }, [currentOperatorId]);

  // muda o provedor selecionado
  function handleChangeOperator(aEvent) {
    console.log('selecionou ', aEvent.target.value);
    setCurrentOperatorId(aEvent.target.value);
  }

  return (
    <div>
      <Loading loading={Boolean(state.loading)} />
      <NotificationContainer />

      {/*
      <Dialog
        open={validationKeyDialogShow}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Atenção</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Para prosseguir, insira o código do Google Authenticator.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Google Authenticator Code"
            type="text"
            fullWidth
            value={validationCode}
            onChange={(event) => setValidationCode(event.target.value)}
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={(event) => handleHideDialog()} color="primary">
            Cancelar
          </Button>
          <Button onClick={(event) => handleChangeKey()} color="primary">
            Alterar
          </Button>
        </DialogActions>
      </Dialog>
*/}

      <Typography variant="h6" align="left" className="ml-3">
        <IntlMessages id="neo.gateway.resources" />
      </Typography>

      <div className="col-12">
        <div className="col-xl-6 col-12">
          {/* Lista de provedores */}
          <Card className="shadow border-0">
            <CardContent>
              <FormControl required className="w-100 mb-2" variant="standard">
                <InputLabel>
                  <IntlMessages id="neo.operators.single" />
                </InputLabel>

                <Select
                  name="currentOperator"
                  value={currentOperatorId}
                  onChange={handleChangeOperator}
                  variant="standard"
                >
                  {operatorsList.map((op, idx) => (
                    <MenuItem key={idx} value={op.id}>
                      {op.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </CardContent>
          </Card>

          {/* Lista de recursos do provedor selecionado */}
          <Card className="shadow border-0 mt-3">
            <CardContent>
              <List className="w100">
                {currentOperatorResources.map((resource) => (
                  <ListItem className="list-row" key={resource.urn}>
                    <ListItemText primary={resource.urn} />
                    <ListItemSecondaryAction>
                      <Switch
                        color="primary"
                        onClick={(event) =>
                          changeResource(resource.urn, event.target.checked)
                        }
                        checked={resource.enabled}
                        disabled={!validKey}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Item;
