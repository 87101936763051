import React, { useReducer } from 'react';
import MainContext from './context';
import MainReducer from './reducer';
import * as defaultSettings from './default';

const INIT_STATE = {
  menuCollapsed: true,
  locale: defaultSettings.locale,
  theme: defaultSettings.theme,
};

const MainProvider = ({ children }) => {
  const [state, dispatch] = useReducer(MainReducer, INIT_STATE);

  return (
    <MainContext.Provider value={{ state, dispatch }}>
      {children}
    </MainContext.Provider>
  );
};

export default MainProvider;
