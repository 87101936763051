import React from 'react';
import Item from './item';

class AccessTestPage extends React.Component {
  render() {
    return (
      <div className="app-wrapper">
        <Item />
      </div>
    );
  }
}
export default AccessTestPage;
