import indigo from '@mui/material/colors/indigo';
import pink from '@mui/material/colors/pink';
import brown from '@mui/material/colors/brown';

export const theme = {
  palette: {
    primary: {
      light: brown[300],
      main: brown[500],
      dark: brown[700],
      contrastText: '#fff',
    },
    secondary: {
      light: pink[300],
      main: pink['A200'],
      dark: pink[700],
      contrastText: '#fff',
    },
  },
  status: {
    danger: 'orange',
  },
  direction: 'ltr',
  typography: {
    button: {
      fontWeight: 400,
      textAlign: 'capitalize',
    },
  },
};

export const locale = 'pt';
