import React, { useState, useRef, useEffect } from 'react';
import { Auth, API, Storage } from 'aws-amplify';
import Loading from 'util/loading';
import { Card, CardContent, CardHeader, CardActions } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { DatePicker } from '@mui/x-date-pickers';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import ReportFile from './reportFile';
import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';
import { Cached } from '@mui/icons-material';
import dayjs from 'dayjs';

const useStyles = makeStyles({
  cardAction: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  cards: {
    marginTop: 12,
    minHeight: 240,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  topBox: {
    columnGap: 12,
  },
  select: {
    maxWidth: 320,
  },
  datePicker: {
    minWidth: 280,
  },
});

const compareFiles = function (a, b) {
  if (a.lastModified > b.lastModified) {
    return -1;
  }

  if (a.lastModified < b.lastModified) {
    return 1;
  }

  return 0;
};

const filterFiles = function (aFile) {
  const fileExtension = aFile.key.split('.').at(-1);
  return plain(fileExtension).includes('csv');
};

function plain(str) {
  return str
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
}

const REPORTKEY_BYSVA = 0;
const REPORTKEY_BYOPERATOR = 1;

const GwReportsPage = () => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const mounted = useRef(null);

  const [operators, setOperators] = useState([]);
  const [urns, setUrns] = useState([]);
  const [s3Files, setS3Files] = useState([]);

  const [alert, setAlert] = useState({
    severity: 'info',
    message: '',
    open: false,
  });

  // se verdadeiro, lista os SVAs de um operador
  // se falso, lista os operadores de um SVA
  const [reportType, setReportType] = useState(REPORTKEY_BYSVA);

  const [currentSva, setCurrentSva] = useState('');
  const [currentOperator, setCurrentOperator] = useState('');

  const dt = new Date();
  const today = dt.toISOString().split('T')[0];
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  const [startDate, setStartDate] = useState(dayjs(yesterday));
  const [finishDate, setFinishDate] = useState(dayjs(today));

  const minDate = '2020-05-10';

  const compareOperators = function (a, b) {
    if (plain(a.name) > plain(b.name)) return 1;
    if (plain(a.name) < plain(b.name)) return -1;
    return 0;
  };

  const compareUrns = function (a, b) {
    if (plain(a.urn) > plain(b.urn)) return 1;
    if (plain(a.urn) < plain(b.urn)) return -1;
    return 0;
  };

  async function listFiles() {
    try {
      setLoading(true);
      const prefix = 'neogateway/';

      let hasNextToken = false;
      const files = [];
      let token = null;

      do {
        const f = await Storage.list(prefix, {
          level: 'public',
          pageSize: 1000,
          nextToken: token,
        });

        files.push(...f.results);
        hasNextToken = Boolean(f.hasNextToken);
        if (hasNextToken) {
          token = f.nextToken;
        }
      } while (hasNextToken);

      setS3Files(files.filter(filterFiles).sort(compareFiles));
    } catch (error) {
      console.log(error);
      setAlert({
        message: error.toString(),
        severity: 'error',
        open: true,
      });
    } finally {
      setLoading(false);
    }
  }

  function monthDiff() {
    let months;
    months =
      (parseInt(finishDate.format('YYYY')) -
        parseInt(startDate.format('YYYY'))) *
      12;
    months -= parseInt(startDate.format('M'));
    months += parseInt(finishDate.format('M'));
    return months <= 0 ? 0 : months;
  }

  async function listOperators() {
    try {
      setLoading(true);
      const apiName = 'lambdaApi';
      const path = '/listOperators';
      const response = await API.get(apiName, path);
      // console.log(response);
      const operatorsList = [
        {
          id: '*',
          name: '*',
        },
      ];
      operatorsList.push(...response.sort(compareOperators));

      setOperators(operatorsList);
    } catch (error) {
      console.log(error);
      setAlert({
        message: error.toString(),
        severity: 'error',
        open: true,
      });
    } finally {
      setLoading(false);
    }
  }

  async function listUrns() {
    try {
      setLoading(true);
      const apiName = 'lambdaApi';
      const path = '/listUrns';
      const response = await API.get(apiName, path);
      // console.log(response);
      const urnList = [
        {
          urn: '*',
        },
      ];
      urnList.push(...response.sort(compareUrns));
      setUrns(urnList);
    } catch (error) {
      console.log(error);
      setAlert({
        message: error.toString(),
        severity: 'error',
        open: true,
      });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    listFiles();
    listUrns();
    listOperators();

    return function cleanup() {};
  }, []);

  function handleCloseAlert() {
    setAlert({
      message: '',
      severity: 'info',
      open: false,
    });
  }

  function handleChangeListKey(aEvent) {
    setReportType(JSON.parse(aEvent.target.value));
  }

  function handleChangeSva(aEvent) {
    setCurrentSva(aEvent.target.value);
  }

  function handleChangeOperator(aEvent) {
    setCurrentOperator(aEvent.target.value);
  }

  function handleChangeStartDate(aValue) {
    setStartDate(aValue);
  }

  async function handleRefreshFiles() {
    await listFiles();
  }

  function handleChangeFinishDate(aValue) {
    setFinishDate(aValue);
  }

  async function handleGenerateReport() {
    if (monthDiff() > 12) {
      setAlert({
        message: 'Diferença maior que 12 meses',
        severity: 'warning',
        open: true,
      });
      return;
    }

    if (finishDate.diff(startDate) < 0) {
      setAlert({
        message: 'Data de fim menor que a de início',
        severity: 'warning',
        open: true,
      });
      return;
    }

    try {
      setLoading(true);

      const apiName = 'lambdaApi';
      const path = '/report';
      let inputs = {
        body: {
          start: startDate.format().split('T')[0],
          finish: finishDate.format().split('T')[0],
        },
        headers: {},
      };

      switch (reportType) {
        case REPORTKEY_BYOPERATOR:
          {
            inputs.body.operator = currentOperator;
          }
          break;

        case REPORTKEY_BYSVA:
          {
            inputs.body.sva = currentSva;
          }
          break;
      }

      const response = await API.post(apiName, path, inputs);
      if (response.toString().includes('Exception')) {
        throw new Error(response);
      } else {
        setAlert({
          message:
            'Solicitação enviada. Aguarde alguns minutos e recarrege a lista de arquivos.',
          severity: 'success',
          open: true,
        });
      }
      // console.log(typeof response);
      // console.log(response);
    } catch (error) {
      console.log(error);
      setAlert({
        message: error.toString(),
        severity: 'error',
        open: true,
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="app-wrapper">
      <Loading loading={loading} />

      <Snackbar
        open={alert.open}
        autoHideDuration={4000}
        onClose={handleCloseAlert}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Alert severity={alert.severity}>{alert.message}</Alert>
      </Snackbar>

      <Card className={classes.cards}>
        <CardContent>
          <Box
            // className="w100 p-3"
            display="flex"
            justifyContent="center"
            className={classes.topBox}
          >
            {/* CheckBox para selecionar sva ou operadora */}
            <FormControl className="w-100" variant="standard">
              <FormLabel id="demo-controlled-radio-buttons-group">
                Chave
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={reportType}
                onChange={(e) => handleChangeListKey(e)}
              >
                <FormControlLabel
                  value={REPORTKEY_BYSVA}
                  control={<Radio />}
                  label="Por SVA"
                />
                <FormControlLabel
                  value={REPORTKEY_BYOPERATOR}
                  control={<Radio />}
                  label="Por operadora"
                />
              </RadioGroup>
            </FormControl>

            <FormControl className={classes.datePicker}>
              <DatePicker
                views={['year', 'month']}
                minDate={dayjs(minDate)}
                maxDate={dayjs(today)}
                label="Data de início"
                value={startDate}
                onChange={(newValue) => handleChangeStartDate(newValue)}
                renderInput={(params) => <TextField {...params} />}
              />
            </FormControl>

            <FormControl className={classes.datePicker}>
              <DatePicker
                views={['year', 'month']}
                minDate={dayjs(minDate)}
                maxDate={dayjs(today)}
                label="Data de fim"
                value={finishDate}
                onChange={(newValue) => handleChangeFinishDate(newValue)}
                renderInput={(params) => <TextField {...params} />}
              />
            </FormControl>
          </Box>

          {reportType === REPORTKEY_BYSVA && (
            <FormControl required className="w-100 mb-2" variant="standard">
              <InputLabel>SVA</InputLabel>

              <Select
                name="currentSvaSelect"
                value={currentSva}
                onChange={(e) => handleChangeSva(e)}
                variant="standard"
                className={classes.select}
              >
                {urns.map((item, idx) => (
                  <MenuItem key={idx} value={item.urn}>
                    {item.urn}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {/* Select sva ou operadora */}
          {reportType === REPORTKEY_BYOPERATOR && (
            <FormControl required className="w-100 mb-2" variant="standard">
              <InputLabel>Operadora</InputLabel>

              <Select
                name="currentOperatorSelect"
                value={currentOperator}
                onChange={(e) => handleChangeOperator(e)}
                variant="standard"
                className={classes.select}
              >
                {operators.map((item, idx) => (
                  <MenuItem key={idx} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </CardContent>
        <CardActions disableSpacing className={classes.cardAction}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleGenerateReport()}
          >
            Enviar
          </Button>
        </CardActions>
      </Card>

      <Card className={classes.cards}>
        <CardHeader
          title="Lista dos últimos relatórios"
          action={
            <Tooltip title="Refresh">
              <Fab
                onClick={(e) => handleRefreshFiles(e)}
                className="jr-fab-btn bg-green text-white jr-btn-fab-xs ml-auto"
              >
                <Cached />
              </Fab>
            </Tooltip>
          }
        />
        <CardContent>
          {s3Files.map((file, idx) => (
            <ReportFile key={idx} file={file} onChange={listFiles} />
          ))}
        </CardContent>
      </Card>
    </div>
  );
};

export default GwReportsPage;
