import React from 'react';
import CustomScrollbars from 'util/CustomScrollbars';
import Navigation from 'components/Navigation';

const SidenavContent = () => {
  const navigationMenus = [
    {
      name: 'neo.gateway.gateway',
      type: 'section',
      children: [
        {
          name: 'neo.gateway.reports',
          type: 'item',
          link: '/gwreports',
        },
        /*         {
          name: 'neo.gateway.2step',
          type: 'item',
          link: '/gateway/2step',
        }, */
        {
          name: 'neo.gateway.resources',
          type: 'item',
          link: '/gateway/resources',
        },
        {
          name: 'neo.gateway.accessTest',
          type: 'item',
          link: '/gateway/accessTest',
        },
      ],
    },
  ];

  return (
    <CustomScrollbars className="scrollbar">
      <Navigation menuItems={navigationMenus} />
    </CustomScrollbars>
  );
};

export default SidenavContent;
