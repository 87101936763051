import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';

import IntlMessages from 'util/IntlMessages';
class Menu extends Component {
  render() {
    return (
      <Fragment>
        <div className="app-main-menu d-none d-md-block w100">
          <ul className="navbar-nav navbar-nav-mega">
            <li className="nav-item">
              <NavLink className="prepend-icon" to="/gwreports">
                <span className="nav-text">
                  <IntlMessages id="neo.gateway.reports.uniqueUsers" />
                </span>
              </NavLink>
            </li>

            {/*  <li className="nav-item">
              <NavLink className="prepend-icon" to="/gateway/2step">
                <span className="nav-text">
                  <IntlMessages id="neo.gateway.2step" />
                </span>
              </NavLink>
            </li> */}

            <li className="nav-item">
              <NavLink className="prepend-icon" to="/gateway/resources">
                <span className="nav-text">
                  <IntlMessages id="neo.gateway.resources" />
                </span>
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink className="prepend-icon" to="/gateway/accessTest">
                <span className="nav-text">
                  <IntlMessages id="neo.gateway.accessTest" />
                </span>
              </NavLink>
            </li>
          </ul>
        </div>
      </Fragment>
    );
  }
}

export default Menu;
