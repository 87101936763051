import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import Typography from '@mui/material/Typography';

const Footer = () => {
  const [username, setUsername] = useState('');

  useEffect(() => {
    async function updateUser() {
      try {
        const user = await Auth.currentAuthenticatedUser({
          bypassCache: true,
        });
        setUsername(user.attributes.email);
      } catch (error) {
        console.log(error);
      }
    }

    updateUser();

    return function cleanup() {};
  }, []);

  return (
    <footer className="app-footer bg-brown text-white p-1">
      <Typography variant="caption" className="ml-auto">
        {username}
      </Typography>
    </footer>
  );
};
export default Footer;
