import React, { useRef, useEffect, useContext, useState } from 'react';
import IntlMessages from 'util/IntlMessages';
import Loading from 'util/loading';
import axios from 'axios';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Card, CardContent } from '@mui/material';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

import { Check, Close } from '@mui/icons-material';
import { API } from 'aws-amplify';

// interceptors colocados para o axios retornar o tempo de resposta
axios.interceptors.request.use((x) => {
  // to avoid overwriting if another interceptor
  // already defined the same object (meta)
  x.meta = x.meta || {};
  x.meta.requestStartedAt = new Date().getTime();
  return x;
});

axios.interceptors.response.use(
  (x) => {
    x.responseTime = new Date().getTime() - x.config.meta.requestStartedAt;
    console.log(
      `Execution time for: ${x.config.url} - ${
        new Date().getTime() - x.config.meta.requestStartedAt
      } ms`
    );
    return x;
  },
  // Handle 4xx & 5xx responses
  (x) => {
    console.error(
      `Execution time for: ${x.config.url} - ${
        new Date().getTime() - x.config.meta.requestStartedAt
      } ms`
    );
    throw x;
  }
);

const Item = (params) => {
  const [loading, setLoading] = useState(false);
  const mounted = useRef(null);
  const [operators, setOperators] = useState([]);
  const [urns, setUrns] = useState([]);

  const [currentOperatorId, setCurrentOperatorId] = useState('');
  const [currentUrn, setCurrentUrn] = useState('');

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const initAuth = {
    access: false,
    request: '',
    response: '',
    responseTime: 0,
  };
  const [authN, setAuthN] = useState(initAuth);
  const [authZ, setAuthZ] = useState(initAuth);

  const [alert, setAlert] = useState({
    severity: 'info',
    message: '',
    open: false,
  });

  useEffect(() => {
    mounted.current = true;

    listOperators();
    listUrns();

    return function cleanup() {
      mounted.current = false;
    };
  }, []);

  async function listOperators() {
    try {
      setLoading(true);
      const apiName = 'lambdaApi';
      const path = '/listOperators';
      const response = await API.get(apiName, path);
      console.log(response);
      setOperators(response.sort(compareOperators));
    } catch (error) {
      console.log(error);
      setAlert({
        message: error.toString(),
        severity: 'error',
        open: true,
      });
    } finally {
      setLoading(false);
    }
  }

  async function listUrns() {
    try {
      setLoading(true);
      const apiName = 'lambdaApi';
      const path = '/listUrns';
      const response = await API.get(apiName, path);
      console.log(response);
      setUrns(response.sort(compareUrns));
    } catch (error) {
      console.log(error);
      setAlert({
        message: error.toString(),
        severity: 'error',
        open: true,
      });
    } finally {
      setLoading(false);
    }
  }

  function plain(str) {
    return str
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
  }

  const compareOperators = function (a, b) {
    if (plain(a.name) > plain(b.name)) return 1;
    if (plain(a.name) < plain(b.name)) return -1;
    return 0;
  };

  const compareUrns = function (a, b) {
    if (plain(a.urn) > plain(b.urn)) return 1;
    if (plain(a.urn) < plain(b.urn)) return -1;
    return 0;
  };

  function handleCloseAlert() {
    setAlert({
      message: '',
      severity: 'info',
      open: false,
    });
  }

  function handleChangeUrn(e) {
    setCurrentUrn(e.target.value);
  }

  function handleChangeOperator(e) {
    console.log(e.target.value);
    setCurrentOperatorId(e.target.value);
  }

  function handleChangePassword(e) {
    setPassword(e.target.value);
  }

  function handleChangeUsername(e) {
    setUsername(e.target.value);
  }

  function prettyJSON(aJson) {
    try {
      return JSON.stringify(JSON.parse(aJson), null, 2);
    } catch (error) {
      console.log(error);
      return aJson;
    }
  }

  async function login() {
    try {
      setLoading(true);
      const url =
        'https://9097yffrd7.execute-api.us-east-1.amazonaws.com/staging/';

      setAuthN(initAuth);
      setAuthZ(initAuth);

      const authNParams = {
        username: username,
        password: password,
        idp: currentOperatorId,
        includeResponse: true,
      };
      const result = await axios.get(url + 'authentication', {
        params: authNParams,
      });
      console.log(result);
      const options = JSON.parse(result.data.response.request.options);
      setAuthN({
        access: Boolean(result.data.access),
        request:
          result.data.response.request.protocol +
          '//' +
          options.host +
          options.path,
        response: prettyJSON(result.data.response.contents),
        responseTime: result.responseTime,
      });

      // se autenticou, tenta autorizar
      if (Boolean(result.data.access)) {
        const authZParams = {
          resource_id: currentUrn,
          subscriber_id: result.data.subscriber_id,
          includeResponse: true,
          passThrough: true,
        };
        const result2 = await axios.get(url + 'authorization', {
          params: authZParams,
        });
        const options2 = JSON.parse(result2.data.response.request.options);
        console.log(result2);
        setAuthZ({
          access: Boolean(result2.data.access),
          request:
            result2.data.response.request.protocol +
            '//' +
            options2.host +
            options2.path,
          response: prettyJSON(result2.data.response.contents),
          responseTime: result2.responseTime,
        });
      }
    } catch (error) {
      console.log(error);
      setAlert({
        message: error.toString(),
        severity: 'error',
        open: true,
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="app-wrapper">
      <Loading loading={Boolean(loading)} />
      <Snackbar
        open={alert.open}
        autoHideDuration={4000}
        onClose={handleCloseAlert}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Alert severity={alert.severity}>{alert.message}</Alert>
      </Snackbar>

      <Typography variant="h6" align="left" className="ml-3">
        <IntlMessages id="neo.gateway.accessTest" />
      </Typography>

      <Card className="shadow border-0">
        <CardContent>
          <div className="row">
            <div className="col-12 col-lg-4 mb-2">
              <FormControl required className="w-100 mb-2" variant="standard">
                <InputLabel>
                  <IntlMessages id="neo.operators.single" />
                </InputLabel>

                <Select
                  name="currentOperator"
                  value={currentOperatorId}
                  onChange={(e) => handleChangeOperator(e)}
                  variant="standard"
                >
                  {operators.map((op, idx) => (
                    <MenuItem key={idx} value={op.id}>
                      {op.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div className="col-12 col-lg-4 mb-2">
              <FormControl required className="w-100 mb-2" variant="standard">
                <InputLabel>
                  <IntlMessages id="neo.gateway.product" />
                </InputLabel>
                <Select
                  name="currentUrn"
                  value={currentUrn}
                  onChange={(e) => handleChangeUrn(e)}
                  variant="standard"
                >
                  {urns.map((item, idx) => (
                    <MenuItem key={idx} value={item.urn}>
                      {item.urn}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div className="col-12 col-lg-4 mb-2">
              <FormControl className="w-100 mb-2" variant="standard">
                <TextField
                  id="username"
                  label="Username"
                  value={username}
                  onChange={handleChangeUsername}
                  variant="standard"
                />
              </FormControl>
              <FormControl className="w-100 mb-2" variant="standard">
                <TextField
                  id="password"
                  label="Password"
                  value={password}
                  onChange={handleChangePassword}
                  variant="standard"
                />
              </FormControl>
              <Button
                variant="contained"
                color="primary"
                disabled={
                  currentUrn.length === 0 ||
                  username.length === 0 ||
                  password.length === 0
                }
                onClick={() => login()}
              >
                Enviar
              </Button>
            </div>
          </div>
        </CardContent>
      </Card>

      <Card className="shadow border-0 mt-3 col-12">
        <CardContent>
          <div className="row">
            <InputLabel>AuthN</InputLabel>
            {authN.access && <Check className="ml-auto text-green" />}
            {!authN.access && <Close className="ml-auto text-red" />}
          </div>
          <pre>
            <code>{authN.request}</code>
          </pre>
          <pre>
            <code>{authN.response}</code>
          </pre>
          <InputLabel>{authN.responseTime} ms</InputLabel>
        </CardContent>
      </Card>

      <Card className="shadow border-0 mt-3 col-12">
        <CardContent>
          <div className="row">
            <InputLabel>AuthZ</InputLabel>
            {authZ.access && <Check className="ml-auto text-green" />}
            {!authZ.access && <Close className="ml-auto text-red" />}
          </div>
          <pre>{authZ.request}</pre>
          <pre>
            <code>{authZ.response}</code>
          </pre>
          <InputLabel>{authZ.responseTime} ms</InputLabel>
        </CardContent>
      </Card>
    </div>
  );
};

export default Item;
