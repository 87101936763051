import React from 'react';
import Item from './item';

class TwoStepPage extends React.Component {
  render() {
    return (
      <div className="app-wrapper">
        <Item {...this.props.location.params} />
      </div>
    );
  }
}
export default TwoStepPage;
