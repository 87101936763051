import React from 'react';
import preval from 'preval.macro';
import Box from '@mui/material/Box';

const HomePage = () => {
  const build = preval`module.exports = new Date().toLocaleString();`;

  const shortCommitSha = preval(`
  const execSync = require('child_process').execSync;
  try {
    module.exports = execSync('git rev-parse --short HEAD').toString().trim();
  } catch (e) {
    module.exports = 'unknown';
  }
  `);

  const branch = preval(`
  const execSync = require('child_process').execSync;
  try {
    module.exports = execSync('git branch').toString().trim();
  } catch (e) {
    module.exports = 'unknown';
  }
  `);

  // mostra (se houver), os arquivos alterados na maquina local
  const modFiles = preval(`
  const execSync = require('child_process').execSync;
  try {
    const lines = execSync('git status --porcelain=v1').toString().split('\\n').filter(n => n);
    module.exports = lines;
  } catch (e) {
    module.exports = [];
  }
  `);

  return (
    <Box className="welcome-container">
      <Box className="welcome-image" />
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box>
          <b>Neo Reports</b>
        </Box>
        <Box>Build Date: {build} </Box>
        <Box>Branch: {branch} </Box>
        <Box>Commit: {shortCommitSha} </Box>
        {modFiles.length > 0 && (
          <Box>
            Local changes:
            <ul>
              {modFiles.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default HomePage;
