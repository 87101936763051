import React, { useReducer } from 'react';
import Context from './context';
import Reducer from './reducer';

const INIT_STATE = {
  operators: [],
  loading: 0,
  alert_change_resource: false,
  changeResource: {
    urn: '',
    value: false,
  },
  validationCode: '',
};

const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, INIT_STATE);

  return (
    <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>
  );
};

export default Provider;
