import React, { useContext, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { IntlProvider } from 'react-intl';
import HorizontalHeader from 'containers/Header/horizontal';
import Footer from 'containers/Footer';

// import { isIOS, isMobile } from "react-device-detect";
import { BrowserRouter } from 'react-router-dom';
import 'assets/vendors/style';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/pt-br';

import MainContext from './context';
import MainRoutes from './routes';

const messages = require('lngProvider/locales/pt_BR.json');

const MainContainer = () => {
  const { state, dispatch } = useContext(MainContext);

  useEffect(() => {
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;

    // if (isIOS && isMobile) {
    //   document.body.classList.add("ios-mobile-view-height");
    // } else if (document.body.classList.contains("ios-mobile-view-height")) {
    //   document.body.classList.remove("ios-mobile-view-height");
    // }
  }, []);

  const applyTheme = createTheme(state.theme);

  return (
    <BrowserRouter>
      <ThemeProvider theme={applyTheme}>
        <IntlProvider locale="pt-BR" messages={messages}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt">
            <div className="app-main">
              <div className="app-container">
                <div className="app-main-container">
                  <HorizontalHeader />
                  <main className="app-main-content-wrapper">
                    <div className="app-main-content">
                      <MainRoutes />
                    </div>
                    <Footer />
                  </main>
                </div>
              </div>
            </div>
          </LocalizationProvider>
        </IntlProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
};
export default MainContainer;
