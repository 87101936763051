import React from 'react';
import { Route, Routes } from 'react-router-dom';

import HomePage from 'app/HomePage';
import TwoStepPage from 'app/2step';
import GwReportsPage from 'app/reports';
import ResourcesPage from 'app/resources';
import AccessTestPage from 'app/accessTest';
import Error404 from 'components/Error404';

const MainRoutes = () => {
  return (
    <Routes>
      <Route exact path={'/'} element={<HomePage />} />
      <Route path={'/gwreports'} element={<GwReportsPage />} />
      <Route path={'/gateway/resources'} element={<ResourcesPage />} />
      <Route path={'/gateway/2step'} element={<TwoStepPage />} />
      <Route path={'/gateway/accessTest'} element={<AccessTestPage />} />

      {/* 404 must be the last one */}
      <Route element={<Error404 />} />
    </Routes>
  );
};

export default MainRoutes;
