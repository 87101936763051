import * as actions from './actions';

const MainReducer = (prevState, action) => {
  switch (action.type) {
    case actions.TOGGLE_MENU:
      return {
        ...prevState,
        menuCollapsed: !prevState.menuCollapsed,
      };

    case actions.CLOSE_MENU:
      return {
        ...prevState,
        menuCollapsed: true,
      };

    default:
      return {
        ...prevState,
      };
  }
};
export default MainReducer;
