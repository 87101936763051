import React, { useContext, Fragment } from "react";
import { Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import { Auth } from "aws-amplify";
import { ExitToApp } from "@mui/icons-material";
import Menu from "./Menu";
import MainContext from "app/main/context";
import * as actions from "app/main/actions";
import Box from "@mui/material/Box";
import SideNav from "../SideNav";

const HorizontalHeader = () => {
  const { dispatch } = useContext(MainContext);

  const onToggleCollapsedNav = () => {
    dispatch({ type: actions.TOGGLE_MENU });
  };

  const onLogout = (e) => {
    Auth.signOut();
  };

  return (
    <Fragment>
      <SideNav />
      <div className="app-header app-header-horizontal">
        <AppBar className="app-main-header">
          <Toolbar className="app-toolbar" disableGutters={false}>
            <div
              className="d-block d-md-none pointer mr-3"
              onClick={onToggleCollapsedNav}
            >
              <span className="jr-menu-icon">
                <span className="menu-icon" />
              </span>
            </div>

            {/* LOGO */}
            <Link className="app-logo mr-2 d-none d-sm-block" to="/">
              <img
                src={require("assets/images/logo.png")}
                alt="Neo"
                title="Neo"
              />
            </Link>

            <Box width={60}></Box>

            <Menu />

            <IconButton
              className="jr-fab-btn bg-transparent text-white jr-btn-fab ml-auto"
              onClick={() => {
                onLogout();
              }}
            >
              <ExitToApp />
            </IconButton>
          </Toolbar>
        </AppBar>
      </div>
    </Fragment>
  );
};

export default HorizontalHeader;
