import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import SidenavContent from './SidenavContent';
import MainContext from 'app/main/context';
import * as actions from 'app/main/actions';

const SideNav = () => {
  const { state, dispatch } = useContext(MainContext);
  let location = useLocation();

  useEffect(() => {
    dispatch({ type: actions.CLOSE_MENU });
  }, [location]);

  const onToggleCollapsedMenu = (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    dispatch({ type: actions.TOGGLE_MENU });
  };

  return (
    <div className="app-sidebar d-none">
      <Drawer
        className="app-sidebar-content"
        variant="temporary"
        open={!state.menuCollapsed}
        onClose={onToggleCollapsedMenu}
        classes={{
          paper: 'side-nav side-menu',
        }}
      >
        <SidenavContent />
      </Drawer>
    </div>
  );
};

export default SideNav;
