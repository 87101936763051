import React from 'react';
import Provider from './provider';
import Item from './item';

const ResourcesPage = () => {
  return (
    <Provider>
      <div className="app-wrapper">
        <Item />
      </div>
    </Provider>
  );
};
export default ResourcesPage;
