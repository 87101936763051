import React from "react";

class Loading extends React.Component {
  render() {
    return (
        (this?.props?.loading) &&

          <div className="pageOverlay">
            <div className="mk-spinner-wrap">
              <div className="mk-spinner-ring"></div>
            </div>
          </div>
    );
  }
}
export default Loading;
