import React, { useState, Fragment } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';
import { Download, Delete } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import Loading from 'util/loading';
import { Storage } from 'aws-amplify';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { writeFile, read } from 'xlsx';

const useStyles = makeStyles({
  rowBox: {
    padding: 4,
    columnGap: 12,
    '&:hover': {
      backgroundColor: '#eaeaea',
    },
  },
  sizeBox: {
    minWidth: 100,
    textAlign: 'right',
    marginRight: 12,
  },
  name: {
    flexGrow: 2,
  },
});

const ReportFile = (props) => {
  const { file } = props;
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const [alert, setAlert] = useState({
    severity: 'info',
    message: '',
    open: false,
  });

  const handleDownload = async function (event) {
    try {
      if (window.document.documentMode) {
        // Do IE stuff
        throw new Error(
          'Download não funciona em modo Internet Explorer. Use outro navegador'
        );
      }

      setLoading(true);

      const result = await Storage.get(file.key, {
        level: 'public',
        expires: 60,
        download: true,
      });
      const url = URL.createObjectURL(result.Body);

      // load the csv from the URL
      const data = await (await fetch(url)).arrayBuffer();

      const wscols = [
        { wch: 40 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
      ];

      // put the contents in a SheetjS Workbook
      const workBook = read(data);
      if (workBook !== null) {
        // altera a largura das colunas
        const worksheetNames = workBook.SheetNames;
        for (const name of worksheetNames) {
          const workSheet = workBook.Sheets[name];
          workSheet['!cols'] = wscols;
          workBook.Sheets[name] = workSheet;
        }

        const fileName = file.key.split('/').at(-1).replace('.csv', '');

        writeFile(workBook, `${fileName}.xlsx`);
      }
    } catch (error) {
      console.log(error);
      setAlert({
        message: error.toString(),
        severity: 'error',
        open: true,
      });
    } finally {
      setLoading(false);
    }
  };

  // quando clica no botão de download
  async function handleDownloadVelho(event) {
    try {
      setLoading(true);
      const result = await Storage.get(file.key, {
        level: 'public',
        expires: 60,
        download: true,
      });

      const url = URL.createObjectURL(result.Body);
      let dwnld = document.createElement('a');
      dwnld.setAttribute('href', url);
      dwnld.setAttribute('download', file.key.split('/').at(-1));
      document.body.appendChild(dwnld);

      let evt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
        clientX: 20,
        /* whatever properties you want to give it */
      });
      dwnld.dispatchEvent(evt);
      document.body.removeChild(dwnld);
    } catch (error) {
      console.log(error);
      setAlert({
        message: error.toString(),
        severity: 'error',
        open: true,
      });
    } finally {
      setLoading(false);
    }
  }

  function handleCloseAlert() {
    setAlert({
      message: '',
      severity: 'info',
      open: false,
    });
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  return (
    <Fragment>
      <Loading loading={Boolean(loading)} />
      <Snackbar
        open={alert.open}
        autoHideDuration={4000}
        onClose={handleCloseAlert}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Alert severity={alert.severity}>{alert.message}</Alert>
      </Snackbar>

      <Box display="flex" flexDirection="row" className={classes.rowBox}>
        <Typography variant="subtitle1" className={classes.name} gutterBottom>
          {file.key.split('/').at(-1).replace('.csv', '')}
        </Typography>

        <Typography variant="caption" gutterBottom>
          <p>{file.lastModified.toLocaleString()}</p>
        </Typography>

        <Typography variant="caption" gutterBottom className={classes.sizeBox}>
          <p>{formatBytes(file.size)}</p>
        </Typography>

        <Tooltip title="Download">
          <Fab
            onClick={(e) => handleDownload(e)}
            className="jr-fab-btn bg-green text-white jr-btn-fab-xs ml-auto"
          >
            <Download />
          </Fab>
        </Tooltip>
      </Box>
    </Fragment>
  );
};

export default ReportFile;
