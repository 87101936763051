import * as actions from './actions';

const Reducer = (prevState, action) => {
  switch (action.type) {

      case actions.SET_VALIDATION_CODE:
        return {
          ...prevState,
          validationCode: action.payload,
        };

    case actions.SET_CURRENT_OPERATOR:
      return {
        ...prevState,
        currentOperator: action.payload,
      };

    case actions.SET_OPERATORS:
      return {
        ...prevState,
        operators: action.payload,
      };

    case actions.LOADING:
      let l = prevState.loading + (action.payload ? 1 : -1);
      return {
        ...prevState,
        loading: l,
      };

    case actions.SHOW_DIALOG:
      return {
        ...prevState,
        alert_change_resource: action.payload,
      };

    case actions.SET_CHANGE_RESOURCE:
      return {
        ...prevState,
        changeResource: action.payload,
      };

    default:
      return {
        ...prevState,
      };
  }
};
export default Reducer;
